import { get, writable } from 'svelte/store';

function createScreenSizeStore() {
    const screenSize = writable<'mobile' | 'tablet' | 'laptop' | 'desktop'>();

    const getSize = () => {
        const width = window.innerWidth;
        if (width < 768) {
            return 'mobile';
        } else if (width < 1024) {
            return 'tablet';
        } else if (width < 1536) {
            return 'laptop'
        }
        return 'desktop';
    };

    if (typeof window !== 'undefined') {
        const resizeObserver = new ResizeObserver(() => {
            if (get(screenSize) !== getSize()) {
                screenSize.set(getSize());
            }
        });

        resizeObserver.observe(document.body);
        screenSize.set(getSize());
    }
    return screenSize;
}

export const screenSize = createScreenSizeStore();
